import React from "react";
import Layout from "../layout";

const NotFoundPage = () => {
    return (
        <Layout>
            Not Found
        </Layout>
    );
}

export default NotFoundPage;